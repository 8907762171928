<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="doFilter">
            <v-row>
              <v-col cols="6" md="2">
                <v-menu
                  ref="menu_from"
                  v-model="menu_from"
                  :close-on-content-click="false"
                  :return-value.sync="date_from"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_from"
                      label="Desde"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-AR"
                    v-model="date_from"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu_from = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_from.save(date_from)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="2">
                <v-menu
                  ref="menu_to"
                  v-model="menu_to"
                  :close-on-content-click="false"
                  :return-value.sync="date_to"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_to"
                      label="Hasta"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-AR"
                    v-model="date_to"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu_to = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_to.save(date_to)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field label="Texto" v-model="search"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  :items="tiposnovedad"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo de Novedad"
                  v-model="tiponovedad"
                ></v-select>
              </v-col>
              <v-col cols="2" md="1" class="col-btn-filter">
                <v-btn
                  icon
                  type="submit"
                  color="secondary"
                  dark
                  @click="doFilter(true)"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "NovedadesFilter",
  data() {
    return {
      date_from: null,
      date_to: null,
      menu_from: null,
      menu_to: null,
      motivos: [],
      motivo: 0,
      tiponovedad: 0,
      tiposnovedad: [],
      search: "",
      novedades: [],
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    let date = new Date();
    this.date_from = new Date(date.getFullYear(), date.getMonth(), 1)
      .toISOString()
      .slice(0, 10);
    this.date_to = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      .toISOString()
      .slice(0, 10);

    PresentismoService.getNovedadesTipos()
      .then((response) => {
        this.tiposnovedad = response.data;
        this.tiposnovedad.push({ id: 0, nombre: "Todos" });
        this.doFilter();
      })
      .catch((error) => console.log(error));
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;

      this.loading = true;
      PresentismoService.getNovedades(
        this.date_from,
        this.date_to,
        this.search,
        this.tiponovedad
      )
        .then((response) => {
          this.novedades = response.data;
          this.$emit("novedades-changed", this.novedades);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      //console.log("haciendo filter con page " + this.pageNumber);
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>

<style>
.col-btn-filter {
  padding-top: 25px;
}
</style>
