<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Novedades</span>
      </v-col>
      <v-col>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small absolute right v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in submenu_items"
              :key="index"
              link
              :disabled="item.disabled"
              :to="
                item.param
                  ? { name: item.link, params: { tipoNovedad: item.param } }
                  : { name: item.link }
              "
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <NovedadesFilter
          @novedades-changed="sendNovedades"
          :pageNumber="pageNumber"
        >
        </NovedadesFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <NovedadesTable
          ref="novedadesTable"
          :novedades="this.novedades.results"
          :novedadesCount="this.novedades.count"
          @paginate="handlePagination"
        >
        </NovedadesTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NovedadesTable from "../components/NovedadesTable.vue";
import NovedadesFilter from "../components/NovedadesFilter.vue";

export default {
  name: "Novedades",
  components: {
    NovedadesTable,
    NovedadesFilter,
  },
  data() {
    return {
      submenu_items: [
        {
          title: "Nueva Licencia",
          index: 1,
          link: "NovedadAlta",
          param: "licencia",
          icon: "mdi-plus",
          disabled: false,
        },
        {
          title: "Nueva Justificación",
          index: 2,
          link: "TurnoAsignar",
          icon: "mdi-plus",
          disabled: true,
        },
        {
          title: "Nueva Autorización de Horas",
          index: 3,
          link: "TurnoAsignar",
          icon: "mdi-plus",
          disabled: true,
        },
        {
          title: "Administrar Motivos",
          index: 4,
          link: "NovedadesMotivos",
          icon: "mdi-playlist-edit",
          disabled: false,
        },
      ],
      novedades: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendNovedades(novedades) {
      // this.pageNumber = "1";
      this.novedades = novedades;
      this.$refs.novedadesTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
