<template>
  <div v-if="novedades">
    <v-data-table
      :headers="headers"
      :items="novedades"
      item-key="id"
      class="elevation-1"
      locale="es-AR"
      no-data-text="No hay datos para mostrar"
      :header-props="headerProps"
      :footer-props="footerProps"
      mobile-breakpoint="500"
      @click:row="selectRow"
      disable-sort
      :server-items-length="novedadesCount"
      hide-default-footer
      :page.sync="page"
      :loading="loading"
      loading-text="Cargando... Espere por favor."
      @update:page="pageUpdateFunction"
    >
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="15"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "NovedadesTable",
  data() {
    return {
      search: "",
      headerProps: {
        sortByText: "Ordenar por",
      },
      footerProps: {
        itemsPerPageText: "Filas por página",
      },
      itemsPerPage: 10,
      page: 1,
      loading: false,
    };
  },
  props: {
    novedades: {
      type: Array,
      required: false,
    },
    novedadesCount: {
      type: Number,
      required: false,
    },
  },
  methods: {
    pageUpdateFunction(newPageNumber) {
      this.loading = true;
      this.novedades = [];
      this.$emit("paginate", newPageNumber.toString());
    },

    selectRow(event) {
      this.$router.push({
        name: "NovedadDetalle",
        params: { id: event.id },
      });
    },
  },

  computed: {
    pageCount: function () {
      if (this.novedadesCount < 10) return 1;
      return Math.ceil(this.novedadesCount / this.itemsPerPage);
    },
    headers() {
      return [
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "get_tipo_display",
        },
        {
          text: "Empleado",
          align: "left",
          sortable: true,
          value: "empleado.__str__",
        },
        {
          text: "Motivo",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "motivo.__str__",
        },
        {
          text: "Desde",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "desde",
        },
        {
          text: "Hasta",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "hasta",
          default: "--",
        },
      ];
    },
  },
};
</script>
